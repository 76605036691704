import { ElementRef, ViewChildren } from '@angular/core';
import { QueryList } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { YoutubePlayerComponent, YoutubePlayerVars } from '@teamfoster/sdk/youtube-player';
import { NgxGlideComponent } from 'ngx-glide';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Slider } from '../../models';
import { ContentSlidersState, getSliderEntities, LoadSlider } from '../../store';

@Component({
  selector: 'app-content-slider',
  templateUrl: './content-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSliderComponent implements OnInit {
  @Input() id!: number;

  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide?: NgxGlideComponent;
  @ViewChild('preSlider') tabPre?: ElementRef<HTMLButtonElement>;
  @ViewChild('afterSlider') tabAfter?: ElementRef<HTMLButtonElement>;
  @ViewChild('preVideo') videoPre?: ElementRef<HTMLButtonElement>;
  @ViewChild('afterVideo') videoAfter?: ElementRef<HTMLButtonElement>;
  // @ViewChildren('videoPlayer') videoPlayer: QueryList<YoutubePlayerComponent>;
  @ViewChild('videoPlayer') videoPlayer?: YoutubePlayerComponent;

  slider$?: Observable<Slider>;
  cookiesAccepted$!: Observable<CookiePreferences | null>;

  playerConfig: YoutubePlayerVars = {
    autoplay: 0,
    controls: 0,
    modestbranding: 1,
  };

  currentSlide = 0;
  state: 'updating' | 'visible' = 'visible';
  videoIsPlaying = false;

  constructor(private store: Store<ContentSlidersState>) {}

  ngOnInit(): void {
    this.store.dispatch(LoadSlider({ id: this.id }));
    this.cookiesAccepted$ = this.store.select(getCookiePreferences);

    this.slider$ = this.store.select(getSliderEntities).pipe(map(a => a[this.id]));
  }

  runStart() {
    this.state = 'updating';
  }
  runEnd() {
    this.state = 'visible';
  }

  updatePage() {
    if (!this.ngxGlide) {
      return;
    }
    this.currentSlide = this.ngxGlide.getIndex();
    this.videoIsPlaying = false;
  }

  goToSlide(i: number) {
    if (!this.ngxGlide) {
      return;
    }
    this.currentSlide = i;
    this.ngxGlide.go(`=${i}`);
  }

  next() {
    if (!this.ngxGlide) {
      return;
    }
    this.ngxGlide.go('>');
  }

  log(e: any, a = null) {
    console.log(a, e);
  }

  tabSkip(forwards: boolean) {
    if (!this.tabAfter || !this.tabPre) {
      return;
    }
    if (forwards) {
      this.tabAfter.nativeElement.focus();
    } else {
      this.tabPre.nativeElement.focus();
    }
  }

  tabSkipVideo(forwards: boolean) {
    if (!this.videoAfter || !this.videoPre) {
      return;
    }
    if (forwards) {
      this.videoAfter.nativeElement.focus();
    } else {
      this.videoPre.nativeElement.focus();
    }
  }

  playVideo() {
    if (!this.videoPlayer) {
      return;
    }
    this.videoPlayer.player.playVideo();
  }

  updateVideoState(playing: boolean) {
    this.videoIsPlaying = playing;
  }
}
