import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSliders from '../reducers/sliders.reducer';

export const getSliderstate = createSelector(fromFeature.getContentSlidersState, (state: fromFeature.ContentSlidersState) => state.sliders);

export const getSliderEntities = createSelector(getSliderstate, fromSliders.getSlidersEntities);

export const getSlidersLoaded = createSelector(getSliderstate, fromSliders.getSlidersLoaded);
export const getSlidersLoading = createSelector(getSliderstate, fromSliders.getSlidersLoading);

export const getAllSliders = createSelector(getSliderEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});
