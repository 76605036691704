<ng-container *ngIf="slider$ | async as slider">
  <div class="content-slider__intro" *ngIf="slider.title || slider.intro">
    <h2 *ngIf="slider.title">{{ slider.title }}</h2>
    <p *ngIf="slider.intro">{{ slider.intro }}</p>
  </div>

  <button *ngIf="slider.slides.length" #preSlider class="focus-only" (click)="tabSkip(true)">Sla verhalen slider over</button>

  <div *ngIf="slider.slides.length" class="content-slider" [class.has--intro]="slider.title || slider.intro">
    <div class="content-slider__slider">
      <ngx-glide
        #NgxGlideComponent
        [gap]="5"
        (ranBefore)="runStart()"
        (ranAfter)="updatePage(); runEnd()"
        [showArrows]="true"
        [showBullets]="false"
        [listenToEvents]="true"
        [autoplay]="false"
        [animationDuration]="300"
        [arrowLeftTemplate]="arrowLeftTemplate"
        [arrowRightTemplate]="arrowRightTemplate"
        [hoverpause]="true"
        [animationDuration]="1200"
      >
        <div class="content-slide" *ngFor="let slide of slider.slides; let i = index">
          <figure class="content-slide__figure slider-video" *ngIf="slide.videoUrl">
            <button *ngIf="!videoIsPlaying && (cookiesAccepted$ | async)" class="slider-video__overlay" (click)="playVideo()">
              <fstr-icon anchor="icon-play"></fstr-icon>
            </button>

            <div class="video-placeholder" *ngIf="i !== currentSlide && (cookiesAccepted$ | async)"></div>
            <div class="video-placeholder video-placeholder__no-cookies" *ngIf="!(cookiesAccepted$ | async)">
              <strong>Deze content wordt niet getoond omdat u cookies niet hebt geacepteerd</strong>
            </div>

            <button #preVideo class="focus-only video-skip" (click)="tabSkipVideo(true)">Sla videospeler over</button>
            <fstr-youtube-player
              class="slider-video__player"
              #videoPlayer
              [attr.tabindex]="videoIsPlaying ? 0 : -1"
              *ngIf="i === currentSlide && (cookiesAccepted$ | async)"
              [src]="slide.videoUrl"
              [config]="playerConfig"
              [cookies]="false"
              (play)="updateVideoState(true)"
            ></fstr-youtube-player>
            <button #afterVideo class="focus-only video-skip video-skip--after" (click)="tabSkipVideo(false)">Sla videospeler over</button>
          </figure>
          <!--<img
            *ngIf="!slide.videoUrl"
            [src]="
              slide.imageUrl || 'https://www.rova.nl/media/uploads/placeholder-image-groen.png' | resize : 992 : 558 : slide?.imageAnchor
            "
            [alt]="slider.title + ' - afbeelding ' + i"
          />-->
          <fstr-image
            [image]="{
              url: slide.imageUrl || 'https://www.rova.nl/media/uploads/placeholder-image-groen.png',
              alt: slider.title + ' - afbeelding ' + i,
              anchor: slide?.imageAnchor
            }"
            [imageSizes]="[1000, 500]"
          ></fstr-image>
        </div>
      </ngx-glide>

      <ng-template #arrowLeftTemplate>
        <button class="glide__arrow__button button button-icon button--light button--md">
          <fstr-icon anchor="icon-arrow-left"></fstr-icon>
        </button>
      </ng-template>

      <ng-template #arrowRightTemplate>
        <button class="glide__arrow__button button button-icon button--light button--md">
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </button>
      </ng-template>
    </div>

    <figcaption class="bg-primary content-slider-content" [ngClass]="'slider-is--' + state">
      <div class="markdown-content text-white" [innerHTML]="slider.slides[currentSlide].text"></div>

      <div class="d-flex justify-content-between flex-wrap">
        <a
          class="button button--light"
          [href]="slider.slides[currentSlide].buttonUrl"
          target="_blank"
          [attr.tabindex]="state === 'updating' ? -1 : 0"
          *ngIf="slider.slides[currentSlide]?.buttonText"
        >
          <span>{{ slider.slides[currentSlide].buttonText }}</span>
        </a>
        <span *ngIf="!slider.slides[currentSlide]?.buttonUrl"></span>

        <button class="button button-outline button--light mr-2" (click)="next()" [attr.tabindex]="state === 'updating' ? -1 : 0">
          <span>Volgende</span>
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </button>
      </div>
    </figcaption>
  </div>

  <button *ngIf="slider.slides.length" #afterSlider class="focus-only" (click)="tabSkip(false)">Terug naar start slider</button>
</ng-container>
